import { debounce } from "lodash"
import { useState, useRef, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  TextField,
  Box,
  Button,
  Typography,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  FormControl,
  InputLabel, Select, MenuItem
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Header, Footer, CustomStepper } from 'swartbox-theme';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import '../styles/PhoneInput.css';
import { useNavigate } from 'react-router-dom';
import { OrderPriceDetail, useOrder, updateRentalType } from 'swartbox-order-library';
import { getAddress } from '../components/address/Address';
import KvKSection from '../components/kvk/kvkSection';
import { schemaOrderCustomerCheckout } from '../components/order/OrderUtils';



const UserDetails = () => {
  const { key } = useParams(); 
  const { order, setOrder } = useOrder();  // Gebruik de order uit de context
  const navigate = useNavigate();
  const [isPrivate, setIsPrivate] = useState(order?.Prospect?.Type?.Code === 'PRIVATE');
  const [kvkData, setKvKData] = useState(order?.Prospect?.Company || { ChamberOfCommerce: '', Name: '' });
  const [additions, setAdditions] = useState(order?.Prospect?.Address?.Addition ? [order?.Prospect?.Address?.Addition] : []);
  const [addressData, setAddressData] = useState(order?.Prospect?.Address);
    
  const steps = [
      { label: 'Bestelling', icon: <ShoppingCartIcon />, link: `/${key}`},
      { label: 'Gegevens', icon: <PersonIcon /> },
      { label: 'Betalen', icon: <PaymentIcon />},
      { label: 'Verzonden', icon: <CheckCircleIcon /> },
  ];  
    
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
    trigger,
    watch
  } = useForm ({
    resolver: yupResolver(schemaOrderCustomerCheckout),
    mode: 'all',
    context: { 
      kvkData,
      isCompany: !isPrivate,
      addressData,
      additions
    },
    defaultValues: {
      firstName: order?.Prospect?.Private?.FirstName || '',
      phoneNumber: order?.Prospect?.Private?.PhoneNumber || '',
      postalCode: order?.Prospect?.Address?.PostalCode || '',
      houseNumber: order?.Prospect?.Address?.Number || '',
      street: order?.Prospect?.Address?.StreetPOBox || '',
      city: order?.Prospect?.Address?.City || '',
      additions: order?.Prospect?.Address?.Addition ? [order?.Prospect?.Address?.Addition] : [],
      houseNumberAddition: order?.Prospect?.Address?.Addition || '',
      kvkObject: kvkData,
      tradingName: order?.Prospect?.Company?.Name || '',
    },
  });

  useEffect(() => {
    trigger(['kvkObject', 'tradingName']);
  }, [kvkData, trigger]);

  const updateFormWithKvKData = (kvkData) => {   
    if (kvkData) {
      const updateKvKData = {
        Name: kvkData.Name,
        ChamberOfCommerce: kvkData.ChamberOfCommerce 
      }
      setKvKData(updateKvKData);
      const addressData = {
        PostalCode: kvkData.PostalCode,
        Number: kvkData.Number,
        StreetPOBox: kvkData.StreetPOBox,
        City: kvkData.City,
        Additions: kvkData.Additions,
        HouseNumberAddition: kvkData.HouseNumberAddition
      }
      updateFormWithAddressData(addressData);
    }else{
      setKvKData({Name: '', ChamberOfCommerce: ''});
    }
  }

  useEffect(() => {
    if (addressData) {
      trigger('postalCode');
      trigger('houseNumber');
      trigger('address');
    }
  }, [addressData, trigger]);

  const updateFormWithAddressData = (addressData) => {
    setAddressData(addressData);
    if (addressData) {
      const houseNumberAdditionValue = addressData.HouseNumberAddition 
        ? addressData.HouseNumberAddition 
        : addressData.Additions && addressData.Additions.length > 0 
        ? addressData.Additions[0] 
        : '';

      setAdditions(addressData.Additions || []);
      setValue('postalCode', addressData.PostalCode || '');
      setValue('houseNumber', addressData.Number || '');
      setValue('street', addressData.StreetPOBox || '');
      setValue('city', addressData.City || '');
      setValue('additions', addressData.Additions || []);
      setValue('houseNumberAddition', houseNumberAdditionValue);
    } else {
      setAdditions([]);
      setValue('street', '');
      setValue('city', '');
      setValue('additions', []);
      setValue('houseNumberAddition', '');
    }
  };

  const postalCode = watch('postalCode');
  const houseNumber = watch('houseNumber');

  const debouncedAddressTrigger = useCallback(
    debounce(async (postalCode, houseNumber) => {
      if (postalCode && houseNumber) {
        const data = await getAddress(postalCode, houseNumber);
        setAddressData(addressData);
        updateFormWithAddressData(data);
      }
    }, 500),
    []
  );
  
  const handlePostalCodeChange = (value) => {
    const formattedPostalCode = value.toUpperCase().replace(/\s/g, '');
    setValue('postalCode', formattedPostalCode);
    debouncedAddressTrigger(formattedPostalCode, houseNumber);
  };

  // Handler for house number change
  const handleHouseNumberChange = (value) => {
    setValue('houseNumber', value);
    debouncedAddressTrigger(postalCode, value);
  };

  const onSubmit = async (data) => {
    const updatedOrder = {
      ...order,
      Prospect: {
        ...order.Prospect,
        Private: {
          ...order.Prospect.Private,
          FirstName: data.firstName,
          PhoneNumber: data.phoneNumber,
        },
        Company: {
            Name: (isPrivate ? '' : data.kvkObject.Name),
            ChamberOfCommerce: (isPrivate ? '' : data.kvkObject.ChamberOfCommerce),
            Tradingname: (isPrivate ? '' : data.tradingName),
        },
        Address: {
          PostalCode: data.postalCode,
          Number: data.houseNumber,
          Addition: data.houseNumberAddition,
          StreetPOBox: data.street,
          City: data.city,
        },
      },
    }

      // Sla de bijgewerkte order op in de state
    setOrder(updatedOrder);

    //console.log(updatedOrder);
    
    navigate(`/${key}/checkout`);

  };

  const handleToggleChange = () => {
    const newType = order?.Prospect
      ? order.Prospect.Type.Code === 'PRIVATE' ? 'COMPANY' : 'PRIVATE'
      : 'PRIVATE';

    let updatedOrder = updateRentalType(order, newType);
    setOrder(updatedOrder);

    setIsPrivate(newType === 'PRIVATE');
    if(newType === 'COMPANY'){
      setKvKData(kvkData);
    }
  };

  return (
    <Box 
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        pb: { xs: '95px', md: 0 },
        maxWidth: 'xl',  // Gebruik de maxWidth uit het thema
        mx: 'auto', // Centraal uitlijnen
      })}
    >
      <Header />
      <Container
        sx={(theme) => ({
          backgroundColor: '#3789B1',
          color: theme.palette.primary.contrastText,
          padding: 2,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          <CustomStepper steps={steps} activeStep={1} />
        </Box>
      </Container>
      <Container
        sx={(theme) => ({
            pt: 4,
            flex: 1,
            backgroundColor: theme.palette.common.white, // Gebruikt de witkleur van het thema
        })}
        >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid container size={{xs:12, md:8}}>
              <Grid size={12}>
                <Typography variant="h2">Gegevens</Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                 Vul hieronder de benodigde persoonlijke gegevens in. Wij gaan strikt zorgvuldig met je persoonlijke gegevens om, gegevens worden daarom niet verstrekt aan derden.
                </Typography>
  
                <ToggleButtonGroup
                  value={isPrivate ? 'PRIVATE' : 'COMPANY'}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="huur type"
                  sx={{ mb: 3 }}
                >
                  <ToggleButton value="PRIVATE" aria-label="Prive">
                    Prive
                  </ToggleButton>
                  <ToggleButton value="COMPANY" aria-label="Zakelijk">
                    Zakelijk
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              {!isPrivate && (
                <KvKSection
                  control={control}
                  setValue={setValue}
                  errors={errors}
                  setDataFromKvK={(data) => {
                    updateFormWithKvKData(data);
                  }}
                  defaultValue={order?.Prospect?.Company}
                />
              )}
              <Grid size={{xs:12, md:6}}>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Voornaam"
                      error={!!errors.firstName}
                      helperText={errors.firstName ? errors.firstName.message : ''}
                      fullWidth
                      sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              field.value && !errors.firstName
                                ? '#4caf50' : '',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{xs:12, md:6}} sx={{ mb: 2 }}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      {...field}
                      defaultCountry="NL"
                      placeholder="Mobielnummer"
                      className={
                        errors.phoneNumber
                          ? 'PhoneInputError'
                          : field.value && !errors.phoneNumber
                            ? 'PhoneInputValid' : ''
                      }
                    />
                  )}
                />
                {errors.phoneNumber && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ ml: '54px' }}
                  >
                    {errors.phoneNumber.message}
                  </Typography>
                )}
              </Grid>
              <Grid size={4}>
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Postcode"
                      onChange={(e) => handlePostalCodeChange(e.target.value)}
                      error={!!errors.postalCode || !!errors.address}  // Check for postalCode or address errors
                      helperText={
                        errors.postalCode ? errors.postalCode.message : errors.address ? errors.address.message : ''
                      }  // Display either postalCode error or address error
                      fullWidth
                      sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              field.value && !errors.postalCode && !errors.address
                                ? '#4caf50' : '',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={4}>
                <Controller
                  name="houseNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Huisnummer"
                      onChange={(e) => handleHouseNumberChange(e.target.value)}
                      error={!!errors.houseNumber || !!errors.address}
                      helperText={
                        errors.houseNumber ? errors.houseNumber.message : ''
                      }
                      fullWidth
                      sx={{
                        mb: 2,
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:
                              field.value && !errors.houseNumber
                                ? '#4caf50' : '',
                          },
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              {additions.length > 0 && (
                <Grid size={4}>
                  <Controller
                    name="houseNumberAddition"
                    control={control}
                    render={({ field }) => (
                      <FormControl
                        fullWidth
                        sx={{
                          mb: 2,
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor:
                                field.value && !errors.houseNumberAddition
                                  ? '#4caf50' : '',
                            },
                          },
                        }}
                        error={!!errors.houseNumberAddition}
                      >
                        <InputLabel>Toevoeging</InputLabel>
                        <Select
                          {...field}
                          label="Toevoeging"
                          MenuProps={{ disablePortal: true }}
                          onChange={(e) => {
                            setValue('houseNumberAddition', e.target.value);
                            field.onChange(e);
                          }}
                          value={field.value || ''} // Default to an empty string if value is null
                        >
                          {additions.map((addition, index) => (
                            <MenuItem key={index} value={addition}>
                              {addition}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.houseNumberAddition && (
                          <Typography variant="caption" color="error">
                            {errors.houseNumberAddition.message}
                          </Typography>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
              )}
              <Grid size={{xs:12, md:6}}>
                <Controller
                  name="street"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Straat"
                      disabled
                      fullWidth
                      sx={{
                        mb: 2,
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid size={{xs:12, md:6}}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Plaats"
                      disabled
                      fullWidth
                      sx={{
                        mb: 2,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid size={{xs:12, md:4}}>
              <OrderPriceDetail ContractLines={order.SwartboxContract.Contract.Lines} OrderLines={order.Lines}>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={isSubmitting}
                >
                  {isSubmitting ? <CircularProgress size={24} /> : 'Volgende'}
                </Button>
              </OrderPriceDetail>
            </Grid>
          </Grid>
        </form>
      </Container>
      <Footer sx={{ display: { xs: 'none', md: 'block' } }} />
    </Box>
  );
}

export default UserDetails;
