import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, Container } from '@mui/material';
import { useOrder, fetchOrder } from 'swartbox-order-library';
import { Header, Footer, CustomStepper, ErrorPage } from 'swartbox-theme';
import api from '../api'; // Zorg ervoor dat je API-configuratie correct is
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Centrifuge } from 'centrifuge';  // Importeer de Centrifuge client
import { ErrorOutline as ErrorOutlineIcon } from '@mui/icons-material';

const steps = [
  { label: 'Bestelling', icon: <ShoppingCartIcon /> },
  { label: 'Gegevens', icon: <PersonIcon /> },
  { label: 'Betalen', icon: <PaymentIcon /> },
  { label: 'Verzonden', icon: <CheckCircleIcon /> },
];

const PendingPaymentStatus = () => {
  const { key } = useParams(); // Haal de order key op uit de URL
  const { order } = useOrder(); // Gebruik de order en loading state uit de context
  const [retryLoading, setRetryLoading] = useState(false); 
  const [paymentStatus, setPaymentStatus] = useState(order.WorkflowStatus.Code);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (paymentStatus === 'PENDING_PAYMENT') {
      const fetchPaymentToken = async () => {
        try {
          const response = await api.put(`/order/${key}/workflow-status`);
          const { channelToken } = response.data;  // Haal de token op van de API
  
          // Maak een verbinding met Centrifugo via WebSocket
          const centrifuge = new Centrifuge("wss://ws.swartbox.nl");
  
          // Monitor verbindingsstatus
          centrifuge.on('connecting', (ctx) => {
            //console.log(`Connecting: ${ctx.code}, ${ctx.reason}`);
          }).on('connected', (ctx) => {
            //console.log(`Connected over ${ctx.transport}`);
          }).on('disconnected', (ctx) => {
            //console.log(`Disconnected: ${ctx.code}, ${ctx.reason}`);
          });
  
          // Open verbinding
          centrifuge.connect();
  
          // Maak een abonnement aan op het payment kanaal voor deze order
          const sub = centrifuge.newSubscription(`orderWofklowStatus_${order.Key}`, {
            token: channelToken // Gebruik het opgehaalde token
          });
  
          // Handeling voor ontvangen publicaties
          sub.on('publication', (ctx) => {        
            let paymentData;
            
            try {
              // Controleer eerst of ctx.data al een object is
              if (typeof ctx.data === 'string') {
                paymentData = JSON.parse(ctx.data);
              } else {
                paymentData = ctx.data;
              }
            } catch (error) {
              console.error("Error parsing payment data:", error);
              return; // Verlaat de handler als er een fout is
            }

            // Controleer of de betaling in progress is en navigeer
            if (paymentData.Code === 'IN_PROGRESS') {
              // navigate(`/${key}`);
            }

            // Stel de status van de betaling in
            setPaymentStatus(paymentData.Code);

            // Verbreek de verbinding alleen als de status niet relevant is
            if (paymentData.Code !== 'PAID') {
              centrifuge.disconnect();
            }
          });        
  
          // Foutafhandeling voor abonnementen
          sub.on('error', (ctx) => {
            //console.error('Subscription error:', ctx);
            setError('Er is een fout opgetreden met de betalingstatus.');  // Update de foutmelding
          });
  
          // Start het abonnement
          sub.subscribe();
  
          // Cleanup: verbreek de verbinding wanneer het component wordt ontmanteld
          return () => {
            centrifuge.disconnect();
          };
  
        } catch (error) {
          //console.error('Error fetching payment status token:', error);
          setError('Er is een fout opgetreden bij het ophalen van de betalingstatus.'); // Stel fout in
        }
      };
  
      // Roep de functie aan om de token op te halen en verbinding te maken
      fetchPaymentToken();
    }
  }, [key, paymentStatus]); 

  const renderStatusMessage = () => {
    switch (paymentStatus) {
      case 'PENDING_PAYMENT':
      case 'PAID':
        return (
          <Box sx={{ textAlign: 'center', mt: 4 }}>

            {/* Main message */}
            <Typography variant="h4" color="warning" sx={{ mt: 2 }}>
              Betaling nog in behandeling
            </Typography>

            {/* Detailed message */}
            <Typography variant="body1" sx={{ mt: 2 }}>
              Je betaling wordt verwerkt. Deze pagina zal automatisch worden bijgewerkt zodra de status verandert.
            </Typography>

            {/* Loader animation */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <CircularProgress size={60} sx={{ color: 'orange' }} />
            </Box>
          </Box>
        );
        case 'TO_PAY':
          return (
            <Box sx={{ textAlign: 'center', mt: 4 }}>
              {/* Error icon for failed payment */}
              <ErrorOutlineIcon sx={{ fontSize: 60, color: 'red' }} />
        
              {/* Error message */}
              <Typography variant="h4" color="error" sx={{ mt: 2 }}>
                Betaling mislukt
              </Typography>
        
              {/* Additional information */}
              <Typography variant="body1" sx={{ mt: 2 }}>
                De betaling is mislukt. Probeer het opnieuw.
              </Typography>
        
              {/* Retry button */}
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 4 }}
                onClick={handleRetry} // Function to retry the payment
                disabled={retryLoading} // Disable the button during loading
                startIcon={retryLoading && <CircularProgress size={20} color="inherit" />} // Add loader inside button
              >
                {retryLoading ? 'Even geduld...' : 'Probeer opnieuw'} {/* Conditional button text */}
              </Button>
            </Box>
          );
      case 'TO_IDENTIFY':
        return (
          <>
            <Typography variant="h4" color="primary">
              Betaling succesvol!
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Bedankt voor je betaling. Je kan je nu gaan legitmeren.
            </Typography>
          </>
        );
        case 'IN_PROGRESS':
          return (
            <>
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography variant="h4" sx={{ mt: 2 }}>
                  Betaling succesvol!
                </Typography>

                <Typography variant="body1" sx={{ mt: 2 }}>
                  Bedankt voor je betaling. Er wordt een contract voor je gegeneerd.
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                  <CircularProgress size={60} sx={{ color: 'orange' }} />
                </Box>
              </Box>
            </>
          );
      default:
        return (
          <>
            <Typography variant="h4" color="error">
              Onbekende status
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Er is een onbekende status ontvangen. Neem contact op met de ondersteuning.
            </Typography>
          </>
        );
    }
  };

  // Functie voor opnieuw proberen
  const handleRetry = async () => {
    setRetryLoading(true);
    try {
      const response = await api.put(`/order/${key}/workflow-transaction/pending-payment`);

      if (response.data.CheckoutUrl) {
        window.location.href = response.data.CheckoutUrl;  // Redirect naar Mollie voor betaling
      } else {
        alert('Er is een fout opgetreden bij het verwerken van de betaling.');
        setRetryLoading(false);
      }
    } catch (error) {
      //console.error('Payment error:', error);
      setError('Er is een fout opgetreden bij het verwerken van de betaling.');
      setRetryLoading(false);
    }
  };

  if (error) return <ErrorPage message={error} />;

  return (
    <Box 
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: 'xl',  
        mx: 'auto', 
      })}
    >
      <Header />
      <Container
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          padding: 2,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          <CustomStepper steps={steps} activeStep={paymentStatus === 'PAID' ? 3 : 2} />
        </Box>
      </Container>
      <Container
        sx={(theme) => ({
          pt: 4,
          flex: 1,
          backgroundColor: theme.palette.common.white,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          {renderStatusMessage()}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default PendingPaymentStatus;
