import React, { useEffect, useState } from 'react';
import { Centrifuge } from 'centrifuge';

const Test = () => {
  const [counter, setCounter] = useState('-');

  useEffect(() => {
    // Maak een verbinding met Centrifugo via WebSocket
    const centrifuge = new Centrifuge("ws://10.7.112.5:9000/connection/websocket", {token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM3MjIiLCJleHAiOjE3Mjg1ODAwNDcsImlhdCI6MTcyNzk3NTI0N30.7CPY6ASzq2DFLkIZbMR-_u4gzjmgHBJHVDdlPzbH3ns"});

    // Verbindingstatus monitoren
    centrifuge.on('connecting', (ctx) => {
      console.log(`Connecting: ${ctx.code}, ${ctx.reason}`);
    }).on('connected', (ctx) => {
      console.log(`Connected over ${ctx.transport}`);
    }).on('disconnected', (ctx) => {
      console.log(`Disconnected: ${ctx.code}, ${ctx.reason}`);
    });

    // Open de verbinding
    centrifuge.connect();

    // Maak een nieuw abonnement aan voor een specifiek kanaal
    const sub = centrifuge.newSubscription('chat2'); // Vervang 'channel' door je eigen kanaalnaam

    // Event-handler voor ontvangen publicaties (berichten)
    sub.on('publication', (ctx) => {
      console.log('Ontvangen publicatie:', ctx.data);
      setCounter(ctx.data.value);  // Update de counter op basis van het ontvangen bericht
      document.title = ctx.data.value;  // Optioneel: Update de paginatitel
    }).on('subscribing', (ctx) => {
      console.log(`Subscribing: ${ctx.code}, ${ctx.reason}`);
    }).on('subscribed', (ctx) => {
      console.log('Subscribed', ctx);
    }).on('unsubscribed', (ctx) => {
      console.log(`Unsubscribed: ${ctx.code}, ${ctx.reason}`);
    });

    // Start het abonnement
    sub.subscribe();

    // Verbreek de verbinding wanneer het component wordt ontmanteld
    return () => {
      centrifuge.disconnect();
    };
  }, []);

  return (
    <div>
      <h1>Centrifugo Test Component</h1>
      <p>Counter: {counter}</p>
    </div>
  );
};

export default Test;
