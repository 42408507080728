import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  CircularProgress,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useNavigate, useParams } from 'react-router-dom';
import { Header, Footer, CustomStepper, ErrorPage, LoadingPage } from 'swartbox-theme';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PersonIcon from '@mui/icons-material/Person';
import PaymentIcon from '@mui/icons-material/Payment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useOrder } from 'swartbox-order-library';
import api from '../api';
import { format } from 'date-fns';
import { schemaOrderCustomerCheckout } from '../components/order/OrderUtils';

const Checkout = () => {
  const { key } = useParams(); 
  const navigate = useNavigate();
  const { order } = useOrder();  // Gebruik de order uit de context
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [countdown, setCountdown] = useState(10);

  const steps = [
    { label: 'Bestelling', icon: <ShoppingCartIcon />, link: `/${key}`},
    { label: 'Gegevens', icon: <PersonIcon />, link: `/${key}/userDetails` },
    { label: 'Betalen', icon: <PaymentIcon />},
    { label: 'Verzonden', icon: <CheckCircleIcon /> },
];   

  useEffect(() => {
    if (!order) {
      navigate(`/${key}`);
    } else {
      //console.log(order);
      validateOrder();
    }
  }, [order]);

  const handleError = (error) => {
    setError(`${error} Je wordt binnen ${countdown} seconden teruggestuurd.`);
    setLoading(false);

    // Start een aftelling van 5 seconden
    let timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(timer); // Stop de timer
          navigate(`/${key}`); // Navigeer na 5 seconden naar de beginpagina
        }
        return prevCountdown - 1; // Verminder de countdown met 1
      });
    }, 1000); // Elke seconde
  };

  const validateOrder = async () => {
    try {
      // Valideer de order met het schema
      const yupObject = {
        firstName: order?.Prospect?.Private?.FirstName,
        phoneNumber: order?.Prospect?.Private?.PhoneNumber,
        postalCode: order?.Prospect?.Address?.PostalCode,
        houseNumber: order?.Prospect?.Address?.Number,
        street: order?.Prospect?.Address?.StreetPOBox,
        city: order?.Prospect?.Address?.City,
        houseNumberAddition: order?.Prospect?.Address?.Addition,
        kvkObject: order?.Prospect?.Company || { ChamberOfCommerce: '', Name: '' },
        tradingName: order?.Prospect?.Company?.Tradingname || ''
      }

      const addressObject = 
      {
        ...order?.Prospect?.Address,
        Additions: order?.Prospect?.Address?.Addition
      }

      await schemaOrderCustomerCheckout.validate(yupObject, { 
        context: { 
          kvkData: order?.Prospect?.Company,
          isCompany: order?.Prospect?.Type?.Code === 'COMPANY',
          addressData: addressObject,
          additions: order?.Prospect?.Address?.Addition ? [order?.Prospect?.Address?.Addition] : [],
        }
      });
      // Als de validatie slaagt, doe niets
      setLoading(false);
    } catch (error) {
      handleError('Validatiefout'); // Roep de foutafhandelingsfunctie aan
    }
  };

  const handlePayment = async () => {
    if (!termsAccepted) {
      alert('Please accept the terms and conditions before proceeding.');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await api.patch(`/order/${order.Key}/checkout`, order);

      if (response.data.CheckoutUrl) {
        window.location.href = response.data.CheckoutUrl;  // Redirect naar Mollie voor betaling
      } else {
        handleError('Er is een fout opgetreden tijdens de checkout');
        setIsSubmitting(false);
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert('Er is een fout opgetreden bij het verwerken van de betaling.');
      setIsSubmitting(false);
    }
  };

  if (loading) return <LoadingPage />;

  if (error) return <ErrorPage message={error} />;

  return (
    <Box 
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        maxWidth: 'xl',  // Gebruik de maxWidth uit het thema
        mx: 'auto', // Centraal uitlijnen
      })}
    >
      <Header />
      <Container
        sx={(theme) => ({
          backgroundColor: '#3789B1',
          color: theme.palette.primary.contrastText,
          padding: 2,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          <CustomStepper steps={steps} activeStep={2} />
        </Box>
      </Container>
      <Container
        sx={(theme) => ({
          pt: 4,
          flex: 1,
          backgroundColor: theme.palette.common.white,
        })}
      >
        <Box
          sx={{
            width: { xs: '100%', md: '83.33%' },
            margin: '0 auto',
          }}
        >
          <Typography variant="h4" sx={{ mb: 2 }}>
            Overzicht te betalen
          </Typography>
          <Typography variant="h6">Jouw gegevens</Typography>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid size={12}>
              <Typography variant="body1">
                <strong>Naam:</strong> {`${order.Prospect.Private.FirstName}`}
              </Typography>
              <Typography variant="body1">
                <strong>Email:</strong> {order.Prospect.Private.Email}
              </Typography>
              <Typography variant="body1">
                <strong>Telefoonnummer:</strong> {order.Prospect.Private.PhoneNumber}
              </Typography>
              <Typography variant="body1">
                <strong>Adres:</strong> {`${order.Prospect?.Address?.StreetPOBox} ${order.Prospect?.Address?.Number}, ${order.Prospect?.Address?.PostalCode} ${order.Prospect?.Address?.City}`}
              </Typography>
              <Link
                component="button"
                variant="body2"
                onClick={() => navigate(`/${order.Key}/userDetails`)}  // Voeg de juiste route toe
                sx={{ mt: 1 }}
              >
                Gegevens aanpassen
              </Link>
            </Grid>
          </Grid>

          {/* Bestellingsinformatie */}
          <Typography variant="h6">Bestelling</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Omschrijving</TableCell>
                  <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    Aantal
                  </TableCell>
                  <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    Prijs
                  </TableCell>
                  <TableCell align="right">Totaal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.Lines.map((line, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {line.Description}
                      {line.Period && (
                        <Typography variant="body2" color="textSecondary">
                          Periode: {`${format(new Date(line.Period.StartDate), 'd MMM')} - ${format(new Date(line.Period.EndDate), 'd MMM yyyy')}`}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      {line.Quantity}
                    </TableCell>
                    <TableCell align="right" sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                      €{line.UnitPrice.toFixed(2)}
                    </TableCell>
                    <TableCell align="right">€{line.TotalAmount.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Totaalbedrag */}
          <Box mt={2} sx={{ textAlign: 'right', mb: 2 }}>
            <Typography variant="body1">
              <strong>Totaalbedrag:</strong> €{order.Finance.TotalAmount.toFixed(2)}
            </Typography>
          </Box>

          {/* Voorwaarden */}
          <FormControlLabel
            control={
              <Checkbox
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                color="primary"
                disableRipple
              />
            }
            label={
              <Typography variant="body2">
                Ik ga akkoord met de{' '}
                <Link
                  href="https://www.swartbox.nl/wp-content/uploads/2024/08/Algemene-Voorwaarden-Self-Storage.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Algemene voorwaarden
                </Link>
              </Typography>
            }
          />

          {/* Betalen-knop */}
          <Button
            variant="contained"
            color="primary"
            disabled={!termsAccepted || isSubmitting} // Deactiveer de knop als de voorwaarden niet zijn geaccepteerd of de betaling bezig is
            onClick={handlePayment}
            sx={{ mt: 2 }}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Betalen'} {/* Toon een loader tijdens het verwerken */}
          </Button>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Checkout;
