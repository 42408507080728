import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import * as yup from 'yup';
import KvKAutocomplete from './kvkAutocomplete';
import api from '../../api';

const getKvkProfile = async (kvkNumber) => {
  if(!kvkNumber){
    return null;
  }
  try {
    const response = await api.get(`/kvk/profile/${kvkNumber}`);
    if (response.status === 200) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error('KvK validation failed:', error);
    return null;
  }
};

const KvKSection = ({ control, setValue, errors, setDataFromKvK, defaultValue }) => {
  const [tradingNames, setTradingNames] = useState([]);

  const handleKvKNumberChange = async (kvkNumber) => {
    const kvkData = await getKvkProfile(kvkNumber);

    if (kvkData) {
      const visitAddress = kvkData._embedded.hoofdvestiging.adressen.find(
        (addr) => addr.type === 'bezoekadres'
      );

      const data = {
        Name: kvkData.handelsnamen[0].naam || '',
        ChamberOfCommerce: kvkData.kvkNummer,
        PostalCode: visitAddress?.postcode || '',
        Number: visitAddress?.huisnummer || '',
        Additions: visitAddress?.huisletter ? [visitAddress.huisletter] : [],
        HouseNumberAddition: visitAddress?.huisletter || '',
        StreetPOBox: visitAddress?.straatnaam || '',
        City: visitAddress?.plaats || '',
      };

      setDataFromKvK(data);
      setTradingNames(kvkData.handelsnamen.map((h) => h.naam));
      setValue('kvkObject', {Name: kvkData.naam || '', ChamberOfCommerce: kvkData.kvkNummer});
      setValue('tradingName', kvkData.handelsnamen[0].naam);
    } else {
      setDataFromKvK(null);
      setValue('kvkObject', {Name: '', ChamberOfCommerce: ''});
      setValue('tradingName','');
      setTradingNames([]);
    }
  };

  return (
    <>
      <Grid size={12}>
        <Controller
            name="kvkObject"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <KvKAutocomplete
                {...field}
                defaultValue={defaultValue}
                error={!!error?.ChamberOfCommerce}
                helperText={error?.ChamberOfCommerce ? error.ChamberOfCommerce.message : null}
                label="Bedrijfsnaam"
                onSelect={(selectedOption) => {
                    handleKvKNumberChange(selectedOption?.kvkNummer);
                }}
              />
            )}
            />
      </Grid>
      {tradingNames.length > 1 && (
        <Grid size={12}>
          <Controller
            name="tradingName"
            control={control}
            render={({ field }) => (
                <FormControl
                fullWidth
                sx={{
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: !errors.tradingName && field.value ? '#4caf50' : '', // Apply green border if valid
                    },
                  },
                }}
                error={!!errors.tradingName} // This will apply error styling if the field is invalid
              >
                <InputLabel>Handelsnaam</InputLabel>
                <Select {...field} label="Handelsnaam" disabled={tradingNames.length === 1}>
                  {tradingNames.map((name, index) => (
                    <MenuItem key={index} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      )}
    </>
  );
};

export default KvKSection;
