import * as yup from 'yup';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const schemaOrderCustomerCheckout = yup.object().shape({
    firstName: yup
      .string()
      .min(2, 'Voornaam moet minimaal 2 letters hebben')
      .required('Voornaam is verplicht'),
    phoneNumber: yup
      .string()
      .test('is-valid', 'Voer een geldig mobiel telefoonnummer in', (value) =>
        value ? isValidPhoneNumber(value) : true
      )
      .required('Telefoonnummer is verplicht'),
      postalCode: yup
      .string()
      .required('Postcode is verplicht')
      .matches(/^[0-9]{4}[A-Z]{2}$/, 'Voer een geldige postcode in het formaat 1234AB in'),
    houseNumber: yup
      .string()
      .required('Huisnummer is verplicht')
      .matches(/^[0-9]/, 'Alleen cijfers zijn toegestaan'),
    street: yup.string(),
    city: yup.string(),
    houseNumberAddition: yup.string().when('$additions', {
      is: (additions) => additions && additions.length > 0,
      then: (schema) => schema.required('Selecteer een huisnummertoevoeging'),
      otherwise: (schema) => schema.nullable(),
    }),
    address: yup.string().when(['postalCode', 'houseNumber'], {
      is: (postalCode, houseNumber) =>
        postalCode && /^[0-9]{4}[A-Z]{2}$/.test(postalCode) && /^[0-9]/.test(houseNumber),
      then: (schema) =>
        schema.test(
          'validate-address',
          'Ongeldig adres. Controleer de postcode en het huisnummer.',
          async function () {
            const { postalCode, houseNumber, houseNumberAddition } = this.parent;
            const { context } = this.options;    

            if (
              context.addressData &&
              String(context.addressData.PostalCode) === postalCode &&
              String(context.addressData.Number) === houseNumber &&
              (context.additions?.includes(houseNumberAddition) || !houseNumberAddition)
            ) {
              return true;
            }
            return false;
          }
        )
    }),
    kvkObject: yup.object().shape({
      ChamberOfCommerce: yup.string().when('$isCompany', {
        is: true,
        then: (schema) =>
          schema
            .required('Bedrijfsnaam is verplicht voor zakelijke klanten')
            .matches(/^\d{8}$/, 'KvK-nummer bestaat uit 8 cijfers')
            .test(
              'kvk-api-validation',
              'Ongeldig KvK-nummer. Controleer het alstublieft opnieuw.',
              function (value) {
                const { context } = this.options;
                if (context.kvkData && context.kvkData.ChamberOfCommerce === value) {
                  return true;
                }
  
                return false;
              }
            ),
        otherwise: (schema) => schema.nullable(), // Als geen bedrijf, dan optioneel
      }),
    }),
    tradingName: yup.string().when('$isCompany', {
      is: true,
      then: (schema) =>
        schema.required('Handelsnaam is verplicht voor zakelijke klanten'),
      otherwise: (schema) => schema.nullable(), // Als geen bedrijf, dan optioneel
    }),
  });