import React from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material'; 
import { theme, ErrorPage } from 'swartbox-theme';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { OrderProvider } from 'swartbox-order-library';

import OrderHandler from './components/order/OrderHandler';

import Test from './pages/Test2';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <OrderProvider>
          <Router>
            <Routes>
              <Route path="/:key" element={<OrderHandler />} />
              <Route path="/:key/:action" element={<OrderHandler />} />
              <Route path="/" element={<ErrorPage message="Key is required" />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="/test" element={<Test />} />
            </Routes>
          </Router>
        </OrderProvider>
    </ThemeProvider>
  );
}

export default App;
