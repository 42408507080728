import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useOrder, fetchOrder, updateOrder } from 'swartbox-order-library';
import { ErrorPage, LoadingPage } from 'swartbox-theme';

import Checkout from '../../pages/Checkout';
import RelationDetails from '../../pages/UserDetails';
import ProspectDetails from '../../pages/ProspectDetails';
import Overview from '../../pages/Overview';
import PendingPaymentStatus from '../../pages/PendingPaymentStatus'; 

function OrderHandler() {
  const { key, action } = useParams();  // Haal de key en action uit de URL-parameters
  const { order, setOrder } = useOrder();
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(null); 

  useEffect(() => {
    const loadOrder = async () => {
      if (!order || order.Key !== key) {  
        try {
          const fetchedOrder = await fetchOrder(key);
          const updatedOrder = updateOrder(order, fetchedOrder);

          setOrder(updatedOrder);
          setInitialLoad(false);
        } catch (error) {
          setError(error.message);
          setInitialLoad(false);
        }
      }
    };

    loadOrder();
  }, [key, order, setOrder]);

  if (initialLoad) return <LoadingPage />;  // Laadindicator alleen tonen als we daadwerkelijk aan het laden zijn

  if (error) return <ErrorPage message={error} />;

  // Gebruik een switch-statement om te routeren op basis van de WorkflowStatus.Code
  if(order){
    switch (order.WorkflowStatus.Code) {
      case "QUOTED":
        switch (action) {
          case "userDetails":
            if(!order.SwartboxContract?.Contract?.Relation?.Key){
              return <ProspectDetails />;
            }else{
              return <RelationDetails />;
            } 
          case "checkout":
            return <Checkout />;
          default:
            return <Overview />;
        }
      case "PENDING_PAYMENT":
      case "TO_PAY":
        return <PendingPaymentStatus />;  
      default:
        return <ErrorPage message="Unknown workflow status" />;
    }
  }
}

export default OrderHandler;
