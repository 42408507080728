import api from '../../api';

export const getAddress = async (postalCode, houseNumber, addition) => {
  if (!postalCode || !houseNumber) return null;

  try {
    const response = await api.post('/address/address', {
      postalCode,
      houseNumber,
      addition
    });
    if (response.status === 200) {
      return response.data; // Return the data if the request is successful
    }
    return null;
  } catch (error) {
    console.error('Address validation failed:', error);
    return null;
  }
};