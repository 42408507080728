import React, { useState, useEffect, forwardRef } from 'react';
import { Autocomplete, TextField, CircularProgress, Typography, Box, ListItem, Divider } from '@mui/material';
import api from '../../api';

const KvKAutocomplete = forwardRef((props, ref) => {
    const {
      label,  
      value,
      defaultValue,
      onChange,
      onBlur,
      name,
      error,
      helperText,
      onSelect,
      ...otherProps
    } = props;
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchKvKData = async (query) => {
    try {
      const response = await api.get(`/kvk/search/${query}`);
      const filteredResults = response.data.resultaten.filter(result => result.type === 'hoofdvestiging');
      return filteredResults;
    } catch (error) {
      console.error('Error fetching KvK data:', error.message);
      return [];
    }
  };

  useEffect(() => {
    if (inputValue.length < 3) {
      setOptions([]);
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      const results = await fetchKvKData(inputValue);
      setOptions(results);
      setLoading(false);
    };

    const delayDebounceFn = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  return (
    <Autocomplete
      freeSolo
      options={options}
      getOptionLabel={(option) => option.Name || ""}
      loading={loading}
      filterOptions={(x) => x}
      value={value}
      defaultValue={defaultValue}
      onInputChange={(event, newInputValue) => {
        if (event && event.type === 'change') {
          setInputValue(newInputValue);
        }
      }}
      onChange={(event, selectedOption) => {
        if (selectedOption) {
          onSelect(selectedOption);
        }else{
            onSelect();
        }
      }}
      renderOption={(props, option) => (
        <React.Fragment key={option.kvkNummer}>
            <ListItem {...props} key={option.kvkNummer}>
            <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>{option.naam}</Typography>
                {option.adres && option.adres.binnenlandsAdres && (
                <Typography variant="body2" color="textSecondary">
                    {option.adres.binnenlandsAdres.straatnaam}, {option.adres.binnenlandsAdres.plaats}, NL
                </Typography>
                )}
                <Typography variant="caption" color="textSecondary">
                KVK-nummer: {option.kvkNummer}
                </Typography>
            </Box>
            </ListItem>
            <Divider component="li" />        
        </React.Fragment>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={helperText}
          label={label}
          sx={{
            mb: 2,
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor:
                  value.ChamberOfCommerce && !error
                    ? '#4caf50' : '',
              },
            },
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
});

export default KvKAutocomplete;
